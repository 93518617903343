const windowGlobal = typeof window !== 'undefined' && window;

const initialState = {
    amount: typeof window !== 'undefined' ? Number(windowGlobal.localStorage.getItem('amount')) : 300,
    amountDefault: 300,
    term: typeof window !== 'undefined' ? Number(windowGlobal.localStorage.getItem('term')) : 91,
    termDefault: 91,
    email: '',
    income: '0',
    registrationValid: false,
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CURRENT_DATA':
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
