import { createStore, combineReducers } from 'redux';

import { formReducer } from './reducers/form';

const rootReducer = combineReducers({
    form: formReducer,
});

export default (preloadedState) => {
    return createStore(rootReducer, preloadedState);
};
